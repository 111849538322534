<template lang="pug">
  .bg-gray-50.min-h-screen.px-4
    Header(:showMenu='false')
    main.pt-8.pb-6.px-4.max-w-lg.mx-auto.bg-white.shadow-content-box
      .prose-content.max-w-prose.mx-auto.text-center
        p Plauder is a user interface for generating text.
        template(v-if='hasPointer')
          p Check out the&nbsp;
            router-link(to='tutorial') interactive tutorial
            | &nbsp;to learn more about it.
          p Or launch the app in your browser:
          .flex.justify-center.mt-6
            Button(
              label='Launch Plauder'
              theme='primary'
              @click='$router.push("/")'
            )
        .text-blue-600(v-else)
          p.font-bold.text-2xl Sorry!
          p This app doesn't support mobile or touch devices yet.
          p Please open <strong>{{ plauderUrl }}</strong> on a desktop computer.
    footer.text-center.pt-8
      router-link.text-sm.text-gray-600.underline(to='about' class='hover:no-underline') About Plauder
</template>

<script>
import { hasPointer } from '@/util'
import Header from '@/components/Header'

export default {
  name: 'Home',

  metaInfo: {
    title: process.env.VUE_APP_TITLE,
    // override the parent template and just use the above title only
    titleTemplate: null
  },

  components: { Header },

  data () {
    return {
      hasPointer: hasPointer,
      plauderUrl: process.env.VUE_APP_PUBLIC_URL
    }
  }

}
</script>
